import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const getDegradations = async() => {
	try {
		const response = await axios.get("/degradations", {
			showLoader: false
		});
		return response.data;
	}
	catch {
		toast(
			"error",
			"Impossible de récupérer les dégradations" + getMessage(error.response.status)
		);
		throw error;
	}
}; 

export default {
	getDegradations
};
