import Vue from "vue";

import sites from "./organization/sites";
import agencies from "./organization/agencies";
import organizations from "./organization/organizations";

import users from "./users";
import guests from "./guests";

import workspaces from "./workspaces";

import campaigns from "./campaigns";

import auscultationCampaigns from "./auscultation/auscultationCampaigns";
import mapLayers from "./auscultation/mapLayers";
import coringMapLayers from "./coring/mapLayers";

import coringCampaigns from "./coring/coringCampaigns";
import cores from "./coring/cores";
import coreLayers from "./coring/coreLayers";
import corePictures from "./coring/corePictures";
import coreInterfaces from "./coring/coreInterfaces";
import roads from "./coring/roads";
import layerNames from "./coring/layerNames";
import displayNamePrefixes from "./coring/displayNamePrefixes";
import materialCategories from "./coring/materialCategories";
import prestations from "./coring/prestations";
import materials from "./coring/materials";
import providers from "./coring/providers";
import images from "./auscultation/images";
import floatDocs from "./auscultation/floatDocs";
import authentication from "./authentication";
import documents from "./documents";
import statistics from "./statistics";
import maorieVersion from "./maorieVersion";

import notifications from "./notifications";
import support from "./support";
import pdfParams from "./coring/pdfParams";
import cities from "./coring/cities";
import download from "./auscultation/download";
import campaignAnalyse from "./coring/campaignAnalyse";
import degradations from "./coring/degradations";

export const api = {
	notifications,
	authentication,

	users,
	guests,
	sites,
	agencies,
	organizations,

	workspaces,
	campaigns,

	auscultationCampaigns,
	mapLayers,

	coringCampaigns,
	cores,
	coreInterfaces,
	coreLayers,
	corePictures,
	coringMapLayers,
	roads,
	displayNamePrefixes,
	materialCategories,
	materials,
	prestations,
	providers,
	images,
	floatDocs,
	documents,
	support,
	statistics,
	layerNames,
	maorieVersion,
	pdfParams,
	cities,
	download,
	campaignAnalyse,
	degradations
};

export default {
	install(){
		Vue.prototype.$api = api;
	}
};
