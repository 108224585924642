import coring from "../../utils/coring";
import {api} from "../../api/index";

export default {
	namespaced: true,
	strict: false,
	state: {
		cores: [],
		coreOnFocus: false,
		editingCore: false,
		movingCore: false,
		isAddingCore: false,
		campaign: false,
		displayNamePrefixes: false,
		roads: false,
		cities: false,
		materialCategories: false,
		otherMaterials: false,
		layerNames: false,
		degradations: false,

		map: {},

		//structural-coring-campaign
		layersList: false,
		selectLayers: false,
		filter: false,
		filterValue: {
			PAH: [],
			TH: [],
			asbestos: [],
		},
		filterByValues: []
	},

	mutations: {
		SET_CORES(state, cores){
			state.cores = cores;
		},
		ADD_CORE(state, params = {}){
			state.cores.push({
				...coring.clearCore,
				...params,
				...{
					number: state.cores.length + 1,
					campaignId: state.campaign.id,
					status: coring.clearCore.status
				}
			});
		},
		UPDATE_CORE(state, core){
			for(let index = 0; state.cores[index]; index++){
				if(state.cores[index].number === core.number){
					let lastLat = state.cores[index].lat;
					let lastLng = state.cores[index].lng;
					state.cores.splice(index, 1, core);
					if(lastLat !== core.lat && lastLng !== core.lng){
						state.editingCore = core;
					}
					break;
				}
			}
		},
		DELETE_CORE(state, core){
			for(let index = 0; state.cores[index]; index++){
				if(state.cores[index].number > core.number) state.cores[index].number--;
				else if(state.cores[index].number === core.number){
					state.cores.splice(index, 1);
					index--;
				}
			}
		},

		SET_CORE_ON_FOCUS(state, core){
			state.coreOnFocus = core;
		},

		START_EDITING_CORE(state, core){
			core = {...core};
			if(core.status === "empty"){
				let lastCore = state.cores.findLast(obj => obj.road !== null) || coring.clearCore;
				core.displayNamePrefix = lastCore.displayNamePrefix;
				core.road = lastCore.road;
				core.city = lastCore.city;
				core.direction = lastCore.direction;
				core.lane = lastCore.lane;
				core.lateralPosition = lastCore.lateralPosition;
				core.structureType = lastCore.structureType;
				core.isFullStructure = lastCore.isFullStructure;
				core.diameter = lastCore.diameter;
			}
			state.editingCore = core;
		},
		STOP_EDITING_CORE(state){
			state.editingCore = false;
		},
		START_ADDING_CORE(state){
			state.isAddingCore = true;
		},
		STOP_ADDING_CORE(state){
			state.isAddingCore = false;
		},

		START_MOVING_CORE(state, number){
			state.movingCore = number;
		},
		STOP_MOVING_CORE(state){
			state.movingCore = false;
		},

		SET_CAMPAIGN(state, campaign){
			state.campaign = campaign;
		},
		SET_CAMPAIGN_STATUS(state, status){
			state.campaign.status = status;
		},

		SET_MAP(state, map){
			state.map = map;
		},

		SET_DNP(state, DNP){
			state.displayNamePrefixes = DNP;
		},

		SET_ROADS(state, roads){
			state.roads = roads;
		},

		SET_CITIES(state, cities){
			state.cities = cities;
		},

		SET_MATERIAL_CATEGORIES(state, materialCategories){
			state.materialCategories = materialCategories;
		},

		SET_OTHER_MATERIAL(state, otherMaterials){
			state.otherMaterials = otherMaterials;
		},

		SET_LAYER_NAMES(state, layerNames){
			state.layerNames = layerNames;
		},

		SET_DEGRADATIONS(state, degradations){
			state.degradations = degradations;
		},

		//structural-coring-campaign
		SET_LAYERS_LIST(state, value){
			state.layersList = value;
		},
		SET_SELECT_LAYERS(state, value){
			state.selectLayers = value;
		},
		SET_FIlTER(state, value){
			state.filter = value;
		},
		SET_FIlTER_PAH(state, value){
			state.filterValue.PAH = value;
		},
		SET_FIlTER_TH(state, value){
			state.filterValue.TH = value;
		},
		SET_FIlTER_ASBESTOS(state, value){
			state.filterValue.asbestos = value;
		},
		SET_FILTER_BY_VALUES(state, object){
			let filter = state.filterByValues;
			let findObjectIndex = state.filterByValues.findIndex(e => e.type === object.type);
			if(findObjectIndex !== -1){
				filter[findObjectIndex] = {type: object.type, value: object.value};
				state.filterByValues = [...filter];
			}
			else {
				state.filterByValues.push({type: object.type, value: object.value});
			}
		},
		REMOVE_FILTER_BY_VALUES(state, index){
			let filter = state.filterByValues;
			filter.splice(index, 1);
			state.filterByValues = [...filter];
		},
		REMOVE_ALL_FILTER_BY_VALUES(state){
			state.filterByValues = [];
		}
	},

	getters: {
		cores(state){
			return state.cores;
		},
		coreOnFocus(state){
			return state.coreOnFocus;
		},
		isCoresValid(state){
			if(state.cores === false || state.cores.length === 0) return false;

			if(state.campaign.status === "awaiting_validation"){
				let test = true;
				for(const core of state.cores){
					if(core.status === "empty") test = false;
				}
				return test;
			}
			else if(state.campaign.status === "taken"){
				let test = false;
				for(const core of state.cores){
					if(core.status === "cancelled") continue;
					if(core.status === "sent_to_study_manager") return false;
					if(core.status === "validated") test = true;
				}
				return test;
			}

		},
		isAddingCore(state){
			return state.isAddingCore;
		},

		campaign(state){
			return state.campaign;
		},
		campaignStatus(state){
			return coring.getInfoStatusCampaign(state.campaign);
		},

		editingCore(state){
			return state.editingCore;
		},

		map(state){
			return state.map;
		},

		movingCore(state){
			return state.movingCore;
		},

		displayNamePrefixes(state){
			return state.displayNamePrefixes;
		},

		roads(state){
			return state.roads;
		},

		cities(state){
			return state.cities;
		},

		materialCategories(state){
			return state.materialCategories;
		},

		otherMaterials(state){
			return state.otherMaterials;
		},

		layerNames(state){
			return state.layerNames;
		},

		degradations(state){
			return state.degradations;
		},

		//structural-coring-campaign
		layersList(state){
			return state.layersList;
		},
		selectLayers(state){
			return state.selectLayers;
		},
		filter(state){
			return state.filter;
		},
		filterValue(state){
			return state.filterValue;
		},
		filterByValues(state){
			return state.filterByValues;
		}
	},

	actions: {
		async initStore({dispatch}, {id, coringResult}){
			let response = await dispatch("setCampaign", id);
			if(response.status === 401 && response.data.code === 5){
				return "site_deactivated";
			}
			await Promise.all([
				dispatch("updateCores", coringResult),
				dispatch("updateRoads"),
				dispatch("updateCities"),
				dispatch("updateDNP"),
				dispatch("updateMaterialCategories"),
				dispatch("updateOtherMaterials"),
				dispatch("updateLayerNames"),
				dispatch("updateDegradations")
			]);
		},

		purgeStore({commit}){
			commit("SET_CORES", []);
			commit("SET_CORE_ON_FOCUS", false);
			commit("SET_CAMPAIGN", false);
			commit("STOP_EDITING_CORE");
			commit("STOP_MOVING_CORE");
			commit("SET_MAP", false);
			commit("SET_FIlTER", false);
		},

		async setCampaign({commit}, id){
			let result = await api.coringCampaigns.findById(id);
			commit("SET_CAMPAIGN", result);
			return result;
		},

		async setCampaignStatus({commit, state}, status){
			await api.coringCampaigns.editStatus(state.campaign.id, status);
			commit("SET_CAMPAIGN_STATUS", status);
		},

		async updateCores({commit, state}, coringResult = false){
			commit("SET_CORES", await api.cores.findByCampaign(state.campaign.id, coringResult));
		},

		async forceUpdateCores({commit}, coringCampaignId){
			commit("SET_CORES", await api.cores.findByCampaign(coringCampaignId));
		},

		async setCoreOnFocus({commit}, core){
			commit("SET_CORE_ON_FOCUS", core);
		},

		async updateRoads({commit, state}){
			commit("SET_ROADS", await api.roads.findByWorkspace(state.campaign.workspace.id));
		},

		async updateCities({commit, state}){
			commit("SET_CITIES", await api.cities.findByWorkspace(state.campaign.workspace.id));
		},

		async updateDNP({commit, state}){
			commit("SET_DNP", await api.displayNamePrefixes.findByCoringCampaignId(state.campaign.id));
		},

		async updateMaterialCategories({commit, state}){
			commit("SET_MATERIAL_CATEGORIES", await api.materialCategories.findAll(state.campaign.workspace.site.organization.id));
		},

		async updateOtherMaterials({commit, state}){
			commit("SET_OTHER_MATERIAL", await api.materials.findOthersByCampaign(state.campaign.id));
		},

		async updateLayerNames({commit, state}){
			commit(
				"SET_LAYER_NAMES",
				await api.layerNames.findByCampaign(state.campaign.id)
			);
		},

		async updateDegradations({commit}){
			commit(
				"SET_DEGRADATIONS",
				await api.degradations.getDegradations()
			);
		},

		async addCore({commit, state}, params){
			commit("ADD_CORE", params);
			commit("UPDATE_CORE", await api.cores.create(state.cores[state.cores.length - 1]));
		},

		async updateCore({commit}, core){
			commit("UPDATE_CORE", await api.cores.edit(core));
		},

		async refreshCore({commit}, {id}){
			commit("UPDATE_CORE", await api.cores.get(id));
		},

		async deleteCore({commit}, core){
			await api.cores.del(core);
			commit("DELETE_CORE", core);
		},

		//structural-coring-campaign
		async setLayersList({commit, state}){
			commit("SET_LAYERS_LIST", await api.coringMapLayers.findByCampaign(state.campaign.id));
		},

	}
};
